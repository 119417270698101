.loader-section {
  position: absolute;
  justify-content: center;
  display: flex;
  z-index: 8888;
  align-items: center;
  width: 100%;
  min-height: calc(100% - 98px);
  background-color: rgba(255, 255, 255, 0.6);
}
