
.feedback-field {
  &:focus {
    outline-color: #ced4da;
  }
}
.feedback-button-div {
 text-align: center;
}

.section__thanks {
  // position: relative;
  height: 100vh;
  background: #f6f6f6;
  .card__container {
    // box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.1);
    background-color: #f6f6f6;
    border: none;
    .card-text {
      font-size: 1.2rem;
    }
    // a {
    //   color: #1e4163 !important;
    // }
  }
  
}
