.App {
  position: relative;
  // height: 100vh;
  min-height: 100vh;
}
.dashboard-card {
  text-align: center;
  margin: 0 auto;
}

.inpute-field {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  &:focus {
    outline-color: #ced4da;
  }
}
