.auth-btn {
  background-color: #1e4163;
  border-color: #1e4163;
  &:hover {
    background-color: #1e4163;
    border-color: #1e4163;
  }
  &:active {
    background-color: #1e4163;
    border-color: #1e4163;
  }
}

.shodow-custom {
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.15) !important;
}

.slick-prev:before,
.slick-next:before {
  color: #1e4163;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #717171;
  border-color: #717171;
}

.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #1e4163;
  border-color: #1e4163;
  &:focus {
    box-shadow: none;
  }
}

.slick-dots li {
  height: 27px;
  width: 27px;
  color: #1e4163;
  border: 2px solid #1e4163;
  border-radius: 50%;
  display: inline-block;
  @media screen and (max-width: 768px) {
    width: 20px;
    height: 20px;
    font-size: 13px;
    line-height: 1rem;
  }
}

.slick-dots {
  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
  }
}
li.slick-active {
  background-color: #1e4163;
  color: #fff;
}

input[type="text"]::placeholder {
  text-align: center;
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
}

::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
}

:-ms-input-placeholder {
  text-align: center;
}

//Collapse css

.card-header {
  background-color: #fff;
  .btn-link {
    color: #000;
    &:hover {
      text-decoration: none;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.font-mobile {
  @media screen and (max-width: 768px) {
    font-size: 0.9rem;
  }
}
.survey-logo-container img {
  max-height: 200px;
}
