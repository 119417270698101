.not__found {
  position: relative;
  height: 100vh;
  background: #f6f6f6;
  .card__container {
    box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.1);
    border: none;
    .card-title {
      font-size: 3.2rem;
    }
    a {
      color: $red-color;
    }
  }
}
